.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #282c34;
  min-height: 100vh;

  font-size: calc(10px + 2vmin);
  color: rgb(209, 209, 209);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nice-things-button {
  max-width: 250px;
  background-color: #c64eaa;
  color: rgb(0, 0, 0);
  border-radius: 20px;
  border: none;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 30px;
}

.something-sweet {
  color: rgb(13, 13, 13);
  border: none;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 30px;
  position: absolute;
  opacity: 0;
  animation: fadeIn 5s ease-in-out;
  font-weight: bold;
  background-color: #c74cb5;
  border-radius: 20px;
  padding: 5px;
}

.timer {
  position: absolute;
  left: 55%;
  top: 3%;
}

.aniv {
  font-size: 50px;
}

.youtubeEmbed {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.usPictures {
  display: flex;
  width: 400px;
  max-height: 500px;
  margin-right: 20px;
}

.middle-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 2500px) {
  .img-size {
    width: 300px;
  }
}

@media screen and (max-width: 1900px) {
  .img-size {
    width: 250px;
  }
}

@media screen and (max-width: 1600px) {
  .img-size {
    width: 200px;
  }
}

@media screen and (max-width: 1450px) {
  .img-size {
    width: 150px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
